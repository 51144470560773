@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

Body {
  background-color: #fdfafa;
  box-sizing: border-box;
}

.App {
  padding: 10px;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.Logo {
  color: black;
  position: relative;
  display: inline-block;
  width: 200px;
  top: -23px;
  text-align: left;
  font-size: 30px;
}

.options-buffer {
  height: 100px;
}

.top-box-desc {
  text-align: left;
  color: rgb(192,123,196);
  font-weight: 600;
  font-size: 12px;
}

.top-box-val {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  font-family: "Roboto Mono", monospace;
}

.balance-logo-image {
  height: 44px;
}

.reward-logo-image {
  margin-right: 49px;
  top: 10px;
  left: 24px;
  height: 44px;
  position: relative;
}

.nyan-balance {
  font-size: 16px;
}

.top-box-container {
  display: inline-block;
  position: absolute;
  right: 5px;
  float: right;
  text-align: left;
  width: 260px;
  border-radius: 10px;
  background-color: #f1f1f1;
  padding: 12px;
}

.balance-box {
  margin-bottom: 0px;
  text-align: center;
}

.stats-box {
}

.stats-op:not(:last-child) {
  margin-bottom: 1px;
}

.Nyan-cat {
  /* background-color: gray; */
  position: relative;
  display: inline-block;
  width: 100px;
  text-align: left;
  font-size: 30px;
  height: 100px;
  background-image: url("./assets/nyan-logo.jpg");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.v2-live {
  width: 90%;
  padding: 10px;
  margin-top: 20px;
  background-color: lightgreen;
  border-radius: 7px;
}

.Options-box {
  width: 100%;
  height: 70%;
  text-align: center;
  margin-top: 3%;
}

.Option {
  width: 240px;
  height: 125px;
  /* background-color: gray; */
  -webkit-box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
  display: inline-block;
  cursor: pointer;
  transition: all .5s;
  border-radius: 30px;
  background-color: white;
}

.Option:first-child {
  margin-right: 12px;
}

.Option:last-child {
  margin-left: 12px;
}

.Option h3 {
  position: absolute;
  bottom: -20px;
  left: 20px;
}

.Option:hover {
  width: 265px;
  height: 140px;
}

.stake {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 30px;
  background-image: url("./assets/Nyan_stake.gif");
  background-position: center right;
  background-size: 43%;
  background-repeat: no-repeat;
}

.button.stake-button.disabled, .button.withdraw-button.disabled  {
  background-color: #f2f2f2;
}

.pumped {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 30px;
  background-image: url("./assets/Nyan_pump.gif");
  background-position:  bottom -10px right 0px;
  background-size: 59%;
  background-repeat: no-repeat;
}

.mine {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 30px;
  background-image: url("./assets/Dark_Nyan.gif");
  background-position:  bottom -2px right 0px;
  background-size: 43%;
  background-repeat: no-repeat;
}

.bid-creator {
  overflow-y: auto;
  height: 98%;
}

.vote-section {
  width: 200px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
}

.v-s-selected {
  background-color: lightgray;
}

.vote-option {
  display: inline-block;
  margin-right: 15px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 15px;
}

.v-op-selected {
  padding: 10px;
  background-color:  #ff95da;
  height: 15px;
  text-decoration: unset;
}

.approve-nyan {
  background-color: #ff95da;
  color: white;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.approve-catnip {
  background-color: #ff95da;
  color: white;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}


.bid {
  /* border: 1px solid black; */
  margin-top: 40px;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
  width: 90%;
  margin: 0 auto;
}

.var-box {
  border: solid 1px #bab5b5;
  height: 210px;
  width: 99%;
  position: relative;
}

.types {
  width: 40%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.types-op {
  width: 84%;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: lightgray;
}

.types-sel {
  background-color:  #ff95da;
  color: white;
}

.values-area {
  width: 60%;
  height: 100%;
  /* border: 1px solid black; */
  border-left: solid 1px #b4b4b4;
  display: inline-block;
  position: absolute;
  text-align: center;
  right: 0;
}

.add-value {
  width: 150px;
  background-color: lightgray;
  padding: 4px 4px 8px 4px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
}

.param-input {
  margin: 0 auto;
  width: 80%;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  outline: none;
}

.parameter {
  background-color: lightgray;
  display: inline-block;
  width: 80%;
  margin: 0 auto;
  margin-left: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 12px;
  padding: 5px;
}

.delete-param {
  width: 15px;
  height: 25px;
  display: inline-block;
  background-color: violet;
  color: white;
  cursor: pointer;
}

.propose {
  width: 100px;
  background-color: lightgray;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.e-proposal {
  width: 100%;
  /* height: 30px; */
  border: solid 1px;
  margin-top: 10px;
  padding: 3px;
}

.view-prop {
  position: absolute;
  right: 23px;
  background-color: azure;
  padding: 3px;
  cursor: pointer;
}

.bid-votes {
  background-color: lightgrey;
  width: 200px;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  margin-top: -10px;
}

.v-bar {
  width: 1px;
  height: 35px;
  margin: 0 auto;
  background-color: lightgray;
}

.add-chain {
  width: 100px;
  background-color:  #ff95da;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.exec-bid {
  width: 170px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  background-color: lightgray;
}

.prop-title {
  margin-top: 10px;
}

.prop-bar {
  width: 100%;
  height: 4px;
  background-color: lightgray;
}

.chain-bid {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.2);
}

.bid-info {
  background-color: beige;
  width: 98%;
  height: 45px;
  padding: 4px;
  border-radius: 5px;
  margin-top: -15px;
}

.show-chain-bid {
  border: solid black;
  border-radius: 7px 0px 0px;
  width: 125px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

.chain-bid-button {
  width: 130px;
  background-color: lightgray;
  padding: 7px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.c-elem {
  width: 150px;
  display: inline-block;
}

.no-bids {
  width: 150px;
  background-color: lightgray;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.claim-withdraw {
  width: 100px;
  background-color: lightgray;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.vote-input {
  margin: 0 auto;
  width: 80%;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  outline: none;
  position: relative;
  display: block;
  margin-top: 15px;
}

.each-g-op {
  width: 90%;
  background-color: lightgrey;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
}

.each-g-op img {
  width: 65%;
}

.approve-gov {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 2px;
  color: white;
  margin-top: 10px;
  background-color: #ff95da;
  cursor: pointer;
}

.governance-vote-count {
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  width: 250px;
  font-weight: bold;
}

.vote-governance {
  width: 150px;
  text-align: center;
  background-color: #ff95da;
  position: absolute;
  padding: 5px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.vote-nyan {
  width: 200px;
  height: 259px;
  position: relative;
  text-align: left;
  font-size: 30px;
  margin: 0 auto;
  right: 0;
  background-image: url("./assets/Nyan_vote.gif");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.address {
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  margin-top: 7px;
  position: absolute;
}

.ny {
  bottom: 150px;
}

.ct {
  bottom: 125px;
}

.dny {
  bottom: 100px;
}

.links-box {
  position: absolute;
  bottom: 70px;
  text-align: center;
  left: 0;
  right: 0;
}

.links-box a {
  font-weight: 600;
  text-decoration: none;
  color: #ff95da;
}

.addr-name {
  display: inline-block;
}

.addr-pink {
  background-color: #ff95da;
  border-radius: 5px;
  /* width: 365px; */
  display: inline-block;
  cursor: pointer;
  color: white;
}

.social-box {
  width: 50%;
  height: 50px;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}


.social-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
}

.git {
  background-image: url("./assets/github.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.twit {
  width: 40px;
  height: 40px;
  background-image: url("./assets/twitter.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.tele {
  background-image: url("./assets/telegram.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.modal {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
  margin: 0 auto;
  width: 90%;
  height: 100%;
  max-width: 768px;
  overflow: scroll;
  padding: 20px;
  border-radius: 5px;
  border-color: red;
  border-width: 10px;
}

.close {
  color: Black;
  float: right;
  width: 20px;
  height: 20px;
  font-size: 32px;
  margin-right: 16px;
}

.close:hover {
  color: pink;
  cursor: pointer;
}

.input {
  width: 100%;
  height: 80px;
  border: none;
  outline: none;
  font-size: 26px;
  padding-left: 10px;
  border-bottom: 3px black solid;
  margin-bottom: 15px;
}

.input-amount {
  width: 98%;
  height: 7vh;
  outline: none;
  font-size: 1em;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
}

/** max container **/
.max-container {
  text-align: right;
  padding-right: 1vh;
  padding-top: 2vh;
  padding-bottom: 1vh;
}

button.as-link {
  color: #ff95da;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}


.amount-staked-box {
  background-color: #f2f2f2;
  width: 97%;
  padding: 10px;
}

.amount-staked-image {
  margin-right: 15px;
}

.button {
  padding: 10px;
  margin: 0 auto;
  background-color: #fca3dc;
  color: #282c34;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.inliner {
  width: 190px;
  margin-right: 10px;
  display: inline-block;
}

.stake-warning {
  color: red;
  margin-bottom: 10px;
}

.withdraw-button {
  margin-top: 10px;
}

.launch-date {
  width: 400px;
  cursor: default;
}

.inline-block {
  display: inline-block !important;
  margin-right: 70px;
}

.gift-icon {
  width: 40px;
  height: 40px;
  background-color: black;
  position: absolute;
  left: 20px;
  bottom: 12px;
  border-radius: 50%;
}

.gift-box {
  width: 150px;
  height: 300px;
  background-color: white;
  position: absolute;
  bottom: 65px;
  left: 20px;
  -webkit-box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
}

/* Style Scrollbars */
*::-webkit-scrollbar {
  width: 8px;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/** Remove input spinners for number fields **/
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.align-left {
  float: left;
}

.align-right {
  float: right;
}

.clear {
  clear: both;
}

@media only screen and (max-width: 900px) {
  
  .Options-box {
    width: unset;
    margin-top: 10%;
  }

  .Option {
    /* width: 95%; */
    height: 110px;
    /* background-color: gray; */
    box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.19);
    /* display: inline-block; */
    cursor: pointer;
    transition: all .5s;
    border-radius: 30px;
    background-color: white;
  }

  .Option:hover {
    width: 255px;
    height: 125px;
  }

  .Option:last-child {
    margin-left: unset;
  }

  .stake, .mine, .pumped {
    /* width: 95%;
    text-align: left;
    font-size: 40px;
    background-size: 15%;
    margin-bottom: 10px; */
  }
}


@media only screen and (max-width: 1024px) {

  .Option {
    /* width: 95%; */
    height: 110px;
  }
  
  .stake, .mine, .pumped {
    /* width: 95%;
    text-align: left;
    font-size: 40px;
    background-size: 10%;
    margin-bottom: 10px; */
  }
}

@media only screen and (max-width: 580px) {
  .top-box-container {
    display: none;
  }
  
  .address {
    position: unset;
  }

  .Option {
    /* width: 95%; */
    height: 125px;
  }

  .stake, .mine, .pumped {
    /* text-align: left;
    font-size: 22px;
    background-size: 25%; */
  }

  .inliner {
    font-size: 15px;
    width: 135px;
    margin-right: 10px;
    display: inline-block;
  }

}
